import React from "react";

const WebDev = () => {
    return (
      <div className="container">
        <ol>
          <li>This website was made using Express.js nad React framworks.</li>
          <li>I made an indernal software tool for Infosys managers where they can add new hires, their resumes and a short discription of their abilities.
              I built the frontend using Express.js and React framworks, as well as some if the backend using Java and springboot framwork.
          </li>
        </ol>

        
        <a href="http://adubal/contact-me" rel="noreferrer" style={{ color: 'white' }}>Link to contact me</a>
      </div>
    );
  };
  
  export default WebDev;